.App {
  text-align: center;
}
.fullCenter{
  align-items:center;
  justify-content:center;
}
.mainContainer {
  display:flex;
  flex: 1;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
}
.subContainer {
  display:flex;
  flex-wrap: wrap;
  flex:1;
  width: 80%;
  margin-top:20px;
  flex-direction:row;
  font-size:20px;
  align-items:center;
  justify-content:space-between;
}
.subText {
  width:300px;
  text-align:left;
  font-size:20px;
  font-weight:400;
  text-overFlow: ellipsis,
}
.btnStyle {
  width:347px;
  border-radius:8px;
  background-color:#424242;
  cursor: pointer;
  color:#FAFAFA;
  height:60px;
}
.addMargin{
  margin-left: 20px;
  margin-right: 20px;
}
/* smartphones, iPhone, portrait 480x320 phones */
@media only screen and (max-width:916px) and (min-width:180px)  {
  .btnStyle {
    width:270px;
    border-radius:8px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color:#424242;
    cursor: pointer;
    color:#FAFAFA;
    height:60px;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
